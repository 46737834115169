.customer-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 50px;
  padding: 10px;
}

.item-name {
  width: 55%;
  text-align: start;
  align-content: center;
}

.item-qty {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 15%;
  text-align: center;
  align-content: center;
}

.order-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  align-self: center;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.product-button {
  width: 40%;
}
.create_order_bt {
  max-width: 250px;
  margin: 0 auto;
}

.product-picker {
  width: 90%;
  background-color: white;
  max-width: 500px;
  max-height: 70vh;
  height: 100%;
  padding: 10px;
  text-align: center;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
}

.product-picker-list {
  overflow-y: auto;
  height: 90%;
}

/* .list-header {
  position: sticky;
  top: 0;
  z-index: 1;
  font-weight: 600;
}
.list-header div {
  background-color: rgb(236, 236, 236);
} */

.w-60 {
  width: 60%;
  text-align: left;
}
.w-20 {
  width: 20%;
}

/* Slider Css */
.PB-range-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background: #d5dbe1;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.PB-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #000000;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.PB-range-slider::-webkit-slider-thumb:hover {
  box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.16);
  transition: 0.3s ease-in-out;
}

.PB-range-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #000000;
  cursor: pointer;
}

.PB-range-slider-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid #c6cace;
  box-shadow: 0px 1px 2px 0px #1f1e241f;
}

.PB-range-slidervalue {
  font-weight: 600;
}

.order-details-header {
  height: "20%";
  display: flex;
  flex-direction: column;
}
.order-details-header p {
  margin: 1px 0;
}

.w-11 {
  width: 11%;
}

.orderDetails div {
  background-color: rgb(236, 236, 236) !important;
  text-align: center;
}

.previous-product {
  background-color: #f8f8f8 !important;
}

@media only screen and (max-width: 992px) {
  .disktop_product_list {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .disktop_product_list {
    width: 500px;
    align-content: center;
    max-height: 71vh;
  }
}

/********************************** Order Header ***************************************************/
.order_header_item {
  width: 50%;
  align-content: center;
}

.order_header_item input {
  background-color: inherit;
  border: 0;
  width: 100%;
  text-align: center;
  height: 100%;
}

/********************************** Order List ***************************************************/

.order-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  max-height: 55vh;
  width: 100%;
  max-width: 600px;
  z-index: 0;
}
.order-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  max-width: 600px;
  scrollbar-width: none;
}

.order_list_productName {
  width: 50%;
  text-align: start;
  align-content: center;
}
.order_list_other_items {
  width: 16.6%;
  text-align: center;
  align-content: center;
}
.order_list_item_editable {
  width: 16.6%;
  align-content: center;
  padding: 0 !important;
  text-align: center;
}
.order_list_item_editable input {
  border: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.order_list_floating_bt {
  font-size: 30px;
  position: absolute;
  z-index: 2;
  height: 50px;
  width: 50px;
  bottom: 40px;
  right: 40px;
  border-radius: 50%;
  border: 0;
  color: #ffffff;
  background-color: #000000;
}

/* Product picker Css Start */

#product_list {
}
