.floating-bg {
  background-color: black;
  border: 2;
  border-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 20px;
  margin-bottom: 40px;
  border-radius: 20px;
  width: 45px;
  height: 45px;
  color: white;
  font-weight: 400;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
}

.LogoutBT {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 125px;
  border-radius: 40px;
  height: 45px;
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
}

/* plus sign */
.logout-sign {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  padding-left: 20px;
  fill: white;
}

/* text */
.logout-text {
  position: absolute;
  right: 0%;
  width: 70%;
  opacity: 1;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  padding-right: 10px;
}

/* button click effect*/
.LogoutBT:active {
  transform: translate(2px, 2px);
}

.submitBT {
  background-color: var(--submitBT);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px;
  margin: 10px 0;
  width: 100%;
  font-weight: 400;
  font-size: 1rem;
}

.submitBT:hover {
  background-color: rgb(43, 43, 43);
}

.loader-delete {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #ff3d00;
  border-style: solid solid dotted;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* RefreshBT Start*/
.rotate-center {
  animation: rotate-center 1.5s linear infinite both;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* RefreshBT End*/
