@media only screen and (min-width: 600px) {
  .dispatch-page {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 93vh;
    padding: 10px 0;
  }

  .dispatch-lists {
    width: 25vw;
    height: 100%;
    max-width: 25vw;
    background-color: #ececec;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: stretch;
    padding: 10px;
    text-align: center;
    overflow-y: auto;
    border-radius: 15px;
  }

  .dispatch-middle {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    padding: 10px;
  }

  .mobile-BT {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .dispatch-page {
    position: relative;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .dispatch-lists {
    background-color: #ececec;
    position: absolute;
    top: 47px;
    left: 5px;
    bottom: 5px;
    right: 5px;
    height: 84vh;
    z-index: 5;
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 10px;
    text-align: center;
    overflow-y: auto;
    border-radius: 15px;
  }
  .hidden-list {
    display: none;
  }

  .dispatch-middle {
    justify-self: center;
    margin: auto 0;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    padding: 10px;
    max-height: 80vh;
  }

  /* Top buttons */
  .mobile-BT {
    width: 100%;
    margin-top: -8px;
  }
  .mobile-BT :nth-child(1) {
    border-radius: 0 0 0 20px;
    border: 1px solid black;
  }
  .mobile-BT :nth-child(2) {
    border-radius: 0 0 20px 0;
    border: 1px solid black;
  }
  .mobile-BT button {
    width: 50%;
    height: 50px;
    border: 0;
    font-weight: 600;
    background-color: #f8f9fa;
  }
  .active-bt {
    background-color: #ececec !important;
  }
}
