.cardsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
}

.detailed-inner {
  width: 100%;
  max-width: 500px;
  height: 75%;
  max-height: 600px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  overflow: auto;
  padding: 0 10px;
}

.list-divider {
  background-color: rgb(228, 228, 228);
  border-radius: 10px;
  margin: 0;
  font-weight: 600;
}
