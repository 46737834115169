:root {
  --submitBT: black;
  --listHeaderBG: rgb(236, 236, 236);
  --editedBG: #fdca40;
}

.App {
  display: flex;
  min-height: 100vb;
  flex-direction: column;
}

.page-main {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
}
.page-full {
  width: 100vw;
  height: 93vh;
}

.max-width {
  width: 100%;

  max-width: 950px;
  margin: 0 auto;
}

.form-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 93vh;
  padding: 10px;
}

.form {
  max-width: 950px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 10px;
  width: 100%;
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form button {
  max-width: 200px;
  margin: 10px auto;
}

.button-submit {
  margin: 20px 0 10px 0;
  background-color: #151717 !important;
  border: none !important;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  cursor: pointer;
}

.button-delete {
  margin: 20px 0 10px 0;
  background-color: #87000e !important;
  border: none !important;
  color: white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  cursor: pointer;
}

.form-tabs-selected {
  background-color: #151717 !important;
  color: white !important;
  font-size: 15px;
  font-weight: 500;
}
.form-tabs {
  background-color: white !important;
  color: #151717 !important;
  font-size: 15px;
  font-weight: 500;
}

.button-submit:hover .form.tabs:hover {
  background-color: #252727 !important;
}

.noLinkUnderline {
  text-decoration: none;
  color: var(--bs-body-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.fixed-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000000b4;
  z-index: 1021;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.fixed-inner {
  width: 100%;
  max-width: 500px;
  height: 75vh;
  max-height: 600px;
  background-color: rgb(255, 255, 255);
  border-radius: 15px;
  padding: 10px;
  overflow: auto;
}

/* List Css */
.sticky {
  position: sticky !important;
  top: 0;
  z-index: 3 !important;
}
.list-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.list-header {
  width: 100%;
  font-weight: 600;
  text-align: left;
}
.list-header div {
  background-color: rgb(236, 236, 236) !important;
}
.list-body {
  width: inherit;
}
.list_larg_field {
  text-align: left;
  align-content: center;
}
.list_small_field {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
  align-content: center;
}

.w-16 {
  width: 16.66%;
}

.clickable_list_item:hover div {
  background-color: lightgray;
  cursor: pointer;
}
/* List Css end*/

.delete-list-item {
  background-color: rgb(216, 0, 0) !important  ;
  text-align: center;
  font-weight: 600;
}
.modify-list-item {
  background-color: rgb(167, 167, 167) !important  ;
  text-align: center;
  font-weight: 600;
}

.edited {
  background-color: var(--editedBG) !important;
}

.form_field {
  min-width: 200px;
}
