.settings-main {
  display: flex;
  height: inherit;
  justify-content: center;
}
.settings-right-side {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  overflow-y: auto;
}

.settings-sage-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  max-width: 850px;
  padding: 20px 10px;
}

.settings-right-side-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  max-width: 850px;
  padding: 20px 10px;
}
.sage_title {
  width: 100%;
  max-width: 850px;
  border-bottom: 1px solid black;
  font-weight: 600;
  font-size: 20px;
}

.sage-login {
  background-color: green;
  border-radius: 25px;
  text-decoration: none;
  color: black;
  align-content: center;
  padding: 20px;
  font-weight: 600;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
}
.sage-logout {
  background-color: red;
  border-radius: 25px;
  text-decoration: none;
  color: black;
  align-content: center;
  padding: 20px;
  font-weight: 600;
  cursor: pointer;
  min-width: 150px;
  text-align: center;
}
