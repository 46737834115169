.filter-list {
  position: absolute;
  min-width: 150px;
  background-color: white !important;
  z-index: 5;
  right: 0;
  text-align: center;
  border-radius: 15px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid rgb(163, 163, 163);
}

.filter-list p:hover {
  background-color: rgb(231, 231, 231);
  cursor: default;
}

.searchIcon {
  height: 1.3rem;
  width: 1.3rem;
  fill: gray !important;
  position: absolute;
  margin-left: 10px;
  margin-top: 10px;
}
.search-input {
  width: 100%;
}
