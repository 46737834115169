.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 1rem;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  background-color: #f3f3f4;
  color: #0d0c22;
  transition: 0.3s ease;
  margin: auto;
}

.input::placeholder {
  color: #9e9ea7;
}

.input:focus,
input:hover {
  outline: none;
  border-color: rgba(247, 127, 0, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(247 127 0 / 10%);
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 1rem;
  height: 1rem;
}

.list-main {
  width: 100%;
  padding: 10px;
  max-width: 500px;
}

.product-list {
  font-weight: 600;
}
.item1 {
  width: 60%;
  text-align: left;
}
.item2-3 {
  width: 15%;
  text-align: center;
  align-content: center;
  padding: 0 !important;
}

.item4 {
  width: 10%;
  text-align: center;
  align-content: center;
  padding: 0 !important;
}
.w-90 {
  width: 90%;
}

.w-20 {
  width: 20%;
}
.w-60 {
  width: 60%;
}
.w-30 {
  width: 30%;
}
