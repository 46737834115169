/* Dispatch quantity list start */
#dispatch_quantity_list_container {
  width: 100%;
}

#dispatch_quantity_list_container div > div:first-child {
  width: 70%;
  padding: 10px 5px;
  text-align: left;
  align-content: center;
}
#dispatch_quantity_list_container div > div:not(:first-child) {
  width: 30%;
  text-align: center;
  padding: 2px;
  align-content: center;
  word-break: break-all;
}
/* Dispatch quantity list end */

/* Shared middle style  start */
.dispatch_middle_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 650px;
  background-color: #ececec;
  padding: 5px;
  border-radius: 15px;
}

/* Dispatch product middle start */

.product_middle_list {
  width: 100%;
  max-height: 40vh;
  overflow-y: auto;
}

.product_middle_list div > div:first-child {
  width: 40%;
  padding: 10px 5px;
  text-align: left;
  align-content: center;
}
.product_middle_list div > div:not(:first-child) {
  width: 20%;
  text-align: center;
  padding: 2px;
  align-content: center;
  word-break: break-all;
}

#product_middle_select_bt {
  display: flex;
  width: 100%;
  column-gap: 20px;
  padding: 5px 20px;
  justify-content: end;
  width: "100%";
}

#product_middle_hint_container {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  column-gap: 20px;
}
#product_middle_hint_container p {
  margin: 0;
  font-weight: 600;
}
#product_middle_hint_container button {
  border-radius: 20px;
  border: 2px solid #dee2e6;
  background-color: white;
  word-break: break-all;
}
/* Dispatch product middle end */

/* Dispatch Cutomer Middle start */

#cutomer_middle_list {
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
}

#cutomer_middle_list div > div:first-child {
  width: 50%;
  padding: 10px 5px;
  text-align: left;
  align-content: center;
}
#cutomer_middle_list div > div:not(:first-child) {
  width: 25%;
  text-align: center;
  padding: 2px;
  align-content: center;
  word-break: break-all;
}

/* Dispatch cutomer middle end */
