.dropdown-container {
  position: relative;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.dropdown-header {
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  transition: transform 0.3s ease;
}

.dropDown-arrow.open {
  transform: rotate(-180deg);
}

.dropdown-list {
  position: absolute;
  right: 0;
  width: fit-content;
  margin: 0;
  padding: 10px;
  list-style: none;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 15px;
  font-size: large;
}

.dropDown-item {
  text-wrap: nowrap;
  padding: 7px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 10px;
}

.dropDown-item:hover {
  background-color: #f0f0f0;
  border-radius: 15px;
}

/* drobdown tab */

.dropdown-tab-down {
  animation: slide-in-top 0.5s;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
