.remove-border {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.card_main {
  width: 20rem;
}
