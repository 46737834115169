.popup-inner {
  background-color: aliceblue;
  height: 70px;
  border-radius: 15px;
  max-width: 400px;
  align-self: flex-start !important;
  display: flex;
  align-items: center;
  justify-content: start;
  animation: slide-bottom 0.5s;
}

.ConfirmationPopup-inner {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  max-width: 500px;
}
.Confirmation_bt_container button {
  border-radius: 15px;
  border: 0;
  max-width: 130px;
}

.Confirmation_bt_container {
  display: flex;
  justify-content: space-evenly;
  column-gap: 5px;
}

@keyframes slide-bottom {
  0% {
    transform: translateY(-70px);
  }
  100% {
    transform: translateY(0);
  }
}
