.list-group {
  display: "flex";
  flex-direction: "column";
  width: "100%";
  align-items: "center";
  justify-content: "center";
  padding: "10px";
}

.list-item {
  display: flex;
  width: 100%;
  padding: 5px 15px;
  text-decoration: none;
  color: black;
}

.list-item div:nth-child(1) {
  width: 30%;
  display: flex;
  align-items: center;
}
.list-item div:nth-child(2) {
  width: 70%;
}

.list-item:hover {
  background-color: rgba(128, 128, 128, 0.154);
  cursor: pointer;
}

.active {
  background-color: #eef7ff;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
@media only screen and (max-width: 770px) {
  .panel-container {
    position: absolute;
    top: 7vh;
    right: 0;
    width: 100vw;
    height: 93vh;
    background-color: rgba(0, 0, 0, 0.234);
    z-index: 2;
  }
  .panel-container-closed {
    width: fit-content;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0);
  }

  .panel-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100vw;
    max-width: 250px;
    padding: 15px 10px;
    background-color: #f8f9fa;
    border-right: 1px solid gray;
    animation: slide-in-left 0.5s;
  }

  .hidden {
    display: none;
  }
}

@media only screen and (min-width: 770px) {
  .panel-container {
    height: inherit;
    width: 20vw;
    border-right: 1px solid gray;
  }

  .panel-container button {
    display: none;
  }
  .panel-main {
    height: 100%;
    width: 20vw;
  }

  .panel-main {
    background-color: #f8f9fa;
  }

  .panel-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 15px 10px;
    width: 20vw;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
