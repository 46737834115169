.addNewProduct {
  max-width: 600px;
}

.manage-list-container {
  height: 70vh;
  width: 100%;
  max-width: 550px;
  padding: 0 5px;
}
